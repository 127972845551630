import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Form } from "react-bootstrap"
import ReactHtmlParser from 'react-html-parser';
import { Link } from "gatsby"
import axios from "axios"
import { API_ROOT } from "gatsby-env-variables"
import { toast } from "react-toastify"
import { object } from "prop-types"

class BlogDetailPage extends React.Component {
  state = {
    body: "",
    blogTitle: "",
    email: ''
  }
  componentDidMount(){
    if(window.$crisp && window.$crisp !== undefined){
      window.$crisp.push(['do', 'chat:hide']);
    }

    var idArr = this.props['*'];
    var id = idArr?.split('/')?.[0];
    this.getBlogDetail(id);
  }
  getBlogDetail(id){
    axios.get(API_ROOT+`/api/v2/posts/${id}`)
      .then( (response) => {
        var res = response?.data?.data;
        this.setState({
          body: res.body,
          headerImage: res.picture['190x160-fit'],
          blogTitle: res.title,
          metaDescription: res.meta_description,
          metaKeyword: res.meta_keyword,
          metaTitle: res.meta_title
        })
      })
  }
  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value
    })
  }
  subscribe = () => {
    if(!this.state.email){
      toast.error('Please enter your email address.')
    }
    axios.post(API_ROOT+`/api/v1/newsletter`,
      {email: this.state.email})
      .then(() => {
        toast.success('Successfully subscribed')
      })
      .catch((error) => {
        if(error.response.status === 422){
          let errors = error.response.data.errors;
          for(let key in errors){
            toast.error(errors[key][0]);
          }
        } else {
          toast.error('An error occured');
        }
      })
  }
  render() {
    return (
      <Layout pageInfo={{ pageName: "Blog detail" }}>
        <SEO title={this.state.metaTitle} keywords={[this.state.metaKeyword]} description={[this.state.metaDescription]} />
        <Container fluid className={'inline-pages'}>
          <Row className={'text-center'}>
            <Col className={'inline-pages-title'} md={{span:10, offset:1}}>
              <p>Blog</p>
            </Col>
            <Col className={'inline-pages-border'} md={{span:10, offset:1}}></Col>
            <Col md={{span:10, offset:1}} className={'p0'}>
              <p className={'bread-crumb'}>
                <Link to="/" className="link-no-style">
                  &nbsp;Home /&nbsp;
                </Link>
                <Link to="/blogs/" className="link-no-style">
                  &nbsp;Blogs /&nbsp;
                </Link>
                {this.state.blogTitle}
              </p>
            </Col>
          </Row>
          <Row>
            <Col className={'inline-pages-content-box blog-section'} md={{span:12}}>
              <Row className={'blog'}>
                <Col md={{span:8, offset:1}} lg={{span:8, offset:1}}>
                  <Col className={'job-detail-header blog-card'} md={'12'}>
                    <h1 className={'job-detail-title'}>{this.state.blogTitle}</h1>
                    <div className={'img-container'} style={{backgroundImage: `url(${this.state.headerImage})`}}></div>
                  </Col>
                  <Col className={'mt20 p-0'} md={'12'}>
                    <Row>
                      <Col md={'12'}>
                        <p className={'job-detail-description'}>
                          { ReactHtmlParser(this.state.body) }
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Col>
                <Col md={2} className={'subscribe'}>
                  <p>Get Our Latest Posts in Your Inbox</p>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name={"company"}
                      value = {this.state.email}
                      onChange = {this.handleEmailChange}
                    />
                  </Form.Group>
                  <button className={'subscribe-btn'} onClick={this.subscribe}>subscribe</button>
                </Col>
              </Row>
            </Col>
            {/*<Col className={'inline-pages-content-box'} md={{span:10, offset:1}}>*/}
            {/*  */}
            {/*</Col>*/}
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default BlogDetailPage
